<template>
  <div class="home">
      <BackBar :pageTit="headTit" />
    <PostItem @reload="refreshPost" v-for="item in posts" :isDescPage="true" :key="'posts'+item.id" :item="item" />
    
  </div>
</template>

<script>
// @ is an alias to /src
import BackBar from '@/components/BackBar.vue'
import Api from '@/api'
import Vue from 'vue'
import PostItem from '@/components/PostItem.vue'

export default {
  name: 'Home',
  data(){
    return {
      posts:[],
      headTit:'详情'
    }
  },
  components: {
    BackBar,
    PostItem
  },
  methods:{
    refreshPost(){
      var that=this
      Api.go('post_desc',{id:this.$route.params.id},(res)=>{
        that.posts=res
        Vue.set(that,'posts',res)
      }) 
    }
  },
  mounted(){
    this.refreshPost()
  }
}
</script>
